/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 27번째 로그에서는 @subicura 님을 모시고 샌프란시스코 여행과 구글 클라우드 넥스트 참관기에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2020"
  }, "RubyKaigi 2020")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/dir/%E6%97%A5%E6%9C%AC%E3%80%81%E3%80%92480-0202+%E6%84%9B%E7%9F%A5%E7%9C%8C%E8%A5%BF%E6%98%A5%E6%97%A5%E4%BA%95%E9%83%A1%E8%B1%8A%E5%B1%B1%E7%94%BA%E5%A4%A7%E5%AD%97%E8%B1%8A%E5%A0%B4+%E5%90%8D%E5%8F%A4%E5%B1%8B%E9%A3%9B%E8%A1%8C%E5%A0%B4%EF%BC%88%E7%9C%8C%E5%96%B6%E5%90%8D%E5%8F%A4%E5%B1%8B%E7%A9%BA%E6%B8%AF%EF%BC%89/%EC%9D%BC%EB%B3%B8+%EB%A7%88%EC%93%B0%EB%AA%A8%ED%86%A0+%EF%BC%88%EB%82%98%EA%B0%80%EB%85%B8%ED%98%84%EF%BC%89/@35.6868656,137.4196544,9z/data=!3m2!4b1!5s0x60037374fc2f46ff:0x9a266a2687d56f30!4m14!4m13!1m5!1m1!1s0x6003a1406c805f4f:0x3ff9c0ed85c30f13!2m2!1d136.9197429!2d35.2538968!1m5!1m1!1s0x601d0e8bd528dceb:0xda2afc863df2bd0f!2m2!1d137.9643552!2d36.2308151!3e3"
  }, "나고야 공항 - 마쓰모토 역까지 경로 - 구글 맵")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%AC%B4%EC%95%88%EA%B5%AD%EC%A0%9C%EA%B3%B5%ED%95%AD"
  }, "무안국제공항 - 위키백과, 우리 모두의 백과사전")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://festa.io/events/183"
  }, "Seocho.rb 첫 번째 모임: 서버리스 루비 | Festa!")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://subicura.com/"
  }, "Subicura's Blog")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://subicura.com/2017/01/19/docker-guide-for-beginners-1.html"
  }, "초보를 위한 도커 안내서 - 도커란 무엇인가?")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.withgoogle.com/next/sf"
  }, "Google Cloud Next ’19 | April 9-11 | San Francisco")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/Moscone+Center/@37.7843234,-122.402884,17z/data=!3m1!4b1!4m5!3m4!1s0x8085807ded297e89:0xd9553880aa393c6c!8m2!3d37.7843234!4d-122.40069"
  }, "Moscone Center - Google 지도")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://kr.hotels.com/ho211817/aleugoneoshotel-eo-nobeul-hauseu-hotel-saenpeulansiseuko-migug/"
  }, "아르고넛호텔 - 어 노블 하우스 호텔 특가 호텔예약, 2019 (샌프란시스코, 미국) 호텔추천 | 호텔스닷컴")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://stdout.fm/3/"
  }, "stdout_003.log: GitHub Universe, HashiConf w/ @Outsideris | 개발자 팟캐스트 stdout.fm")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/retail/appleparkvisitorcenter/"
  }, "Apple Park Visitor Center - Apple")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/shop/buy-ipad/ipad-mini"
  }, "iPad mini 구입하기 - Apple (KR)")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.wsj.com/articles/samsung-galaxy-fold-non-review-we-are-not-your-beta-testers-11555691833"
  }, "Samsung Galaxy Fold Non-Review: We Are Not Your Beta Testers - WSJ")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/%EA%B5%AC%EA%B8%80/@52.1817315,20.9700131,18z/data=!4m5!3m4!1s0x0:0x6c296c66619367e0!8m2!3d52.1817315!4d20.9700131"
  }, "구글플렉스 - Google 지도")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Android_lawn_statues"
  }, "Android lawn statues - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/MPK+12,+facebook+hq+building/@37.4846102,-122.1516928,17z/data=!3m1!4b1!4m5!3m4!1s0x808fbc965117fa47:0xa0d4bbc2595ab193!8m2!3d37.4846102!4d-122.1495041"
  }, "MPK 12, facebook hq building - Google 지도")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=119569176"
  }, "알라딘: 카오스 멍키 - 혼돈의 시대, 어떻게 기회를 낚아챌 것인가")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theatlantic.com/technology/archive/2013/03/trust-but-verify-what-facebooks-electronics-vending-machines-say-about-the-company/273905/"
  }, "Trust, but Verify: What Facebook's Electronics Vending Machines Say About the Company - The Atlantic")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://chrome.google.com/webstore/detail/chrome-remote-desktop/gbchcmhmhahfdphkhkmpfmihenigjmpp"
  }, "Chrome 원격 데스크톱 - 확장 프로그램")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://tech.drecom.co.jp/ruby-kaigi-yatai-sponsor/"
  }, "(일본어) Drecon은 올 해 RubyKaigi 2019에서 야타이 스폰스로 참여합니다! - Tech Inside Drecom")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/search?q=%23rubykaraoke&src=typed_query"
  }, "#rubykaraoke - Twitter Search / Twitter")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.geekwire.com/2019/party-like-1999-jeff-bezos-robert-downey-jr-will-headline-remars-fest-vegas/"
  }, "Jeff Bezos and Robert Downey Jr. will headline re:MARS fest in Vegas – GeekWire")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://festa.io/events/263"
  }, "Google I/O Viewing Party 2019 | Festa!")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://landing.google.com/sre/"
  }, "Google - Site Reliability Engineering")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://objectcomputing.com/"
  }, "Home | OCI")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://objectcomputing.com/products/micronaut"
  }, "Micronaut | OCI")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/micronautfw/status/1115780576093659137"
  }, "Micronaut Framework on Twitter: \"Love it when we run into fellow #micronautfw enthusiasts at events! @subicura ... ")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://logrocket.com/"
  }, "LogRocket | Logging and Session Replay for JavaScript Apps")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://stdout.fm/16/"
  }, "stdout_016.log: 정부의 SNI 기반 인터넷 접속 차단 w/ han | 개발자 팟캐스트 stdout.fm")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/02/06/iphone-session-replay-screenshots/"
  }, "Many popular iPhone apps secretly record your screen without asking | TechCrunch")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://circleci.com/"
  }, "Continuous Integration and Delivery - CircleCI")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/"
  }, "HashiCorp: Multi-Cloud Management, Security, Automation")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/anthos/?hl=ja"
  }, "Anthos  |  Anthos  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.canalys.com/newsroom/cloud-market-share-q4-2018-and-full-year-2018"
  }, "Canalys Newsroom- Cloud market share Q4 2018 and full year 2018")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.amazonaws.cn/en/new/2016/announcing-the-aws-china-beijing-region/"
  }, "Announcing the AWS China (Beijing) Region")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/topics/infrastructure/google-cloud-announces-new-regions-in-seoul-and-salt-lake-city"
  }, "Google Cloud announces new regions in Seoul and Salt Lake City | Google Cloud Blog")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2017/11/17/16670268/apple-homepod-delayed-2018"
  }, "Apple’s HomePod delayed until next year - The Verge")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/circuitbreaker/2019/3/29/18287383/apple-airpower-wireless-charger-cancelled"
  }, "Apple cancels AirPower wireless charger - The Verge")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/bigquery/"
  }, "BigQuery - 분석 데이터 웨어하우스  |  BigQuery  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/athena/"
  }, "Amazon Athena – 서버리스 대화식 쿼리 서비스 – AWS")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/cloudtrail/"
  }, "AWS CloudTrail – Amazon Web Services")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/athena/latest/ug/partitions.html"
  }, "데이터 파티셔닝 - Amazon Athena")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/open-source/bringing-the-best-of-open-source-to-google-cloud-customers"
  }, "Bringing the best of open source to Google Cloud customers | Google Cloud Blog")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/memorystore/"
  }, "Memorystore  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/code/"
  }, "Cloud Code  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://atom.io/"
  }, "Atom")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/run/"
  }, "Cloud Run  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/functions/"
  }, "Cloud Functions - 이벤트 기반 서버리스 컴퓨팅  |  Cloud Functions  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/fargate/"
  }, "AWS Fargate – 서버 또는 클러스터를 관리할 필요 없이 컨테이너를 실행")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/run/pricing"
  }, "Pricing  |  Cloud Run  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/apigee/"
  }, "API 관리  |  Apigee  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/Outsideris/status/1120791051168387072"
  }, "Outsider on Twitter: \"뉴스레터에 올릴 글을 모을 때 한국어로 된 글이 많지 않다는 것에 ...")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/category/Newsletter"
  }, "Outsider's Dev Story - Newsletter")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "www.itcle.com/"
  }, "itcle - 페이지 읽기 오류")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/bigquery/"
  }, "BigQuery - 분석 데이터 웨어하우스  |  BigQuery  |  Google Cloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.zdnet.com/article/google-announces-new-ai-tools-smart-analytics/"
  }, "Google announces new AI, smart analytics tools | ZDNet")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
